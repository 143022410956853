<template>
  <q-card>
    <q-table
      row-key="id"
      :rows="data"
      :columns="columns"
      :v-model:pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: data.length }"
      :rows-per-page-options="[]"
      table-header-class="d-none"
      virtual-scroll
      binary-state-sort
      flat
    >
      <template v-slot:body="props">
        <q-tr
          :props="props"
          class="clickable"
          @dblclick="handleClick(props.row)"
        >
          <q-td
            key="details"
            :props="props"
          >
            <div class="row items-center q-pt-xs">
              <q-badge
                :color="stateColors[props.row.state]"
                :label="$t(props.row.state)"
                class="q-py-xs"
              />

              <div class="col q-px-sm text-caption text-capitalize">
                {{ props.row.type }}
              </div>

              <div v-if="props.row.created">
                {{ $moment(props.row.created.date).format($appOptions.formats.date) }}
              </div>
            </div>

            <div
              v-if="props.row._embedded && props.row._embedded.warehouse"
              class="text-caption"
            >
              <small>{{ props.row._embedded.warehouse.name }}</small>
            </div>

            <div class="text-caption">
              {{ props.row.comment }}
            </div>

            <div class="text-subtitle2 text-bold">
              {{ props.row.id }}
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </q-card>
</template>

<script>
export default {
  name: 'DynamicInventoryCollection',
  emits: ['click'],
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '',
          name: 'details',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        complete: 'success',
        checking: 'warning',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark'
      }
    }
  },
  computed: {
    title () {
      if (this.rawData.options) {
        return this.rawData.options.title
      }

      return this.rawData.title || ''
    }
  },
  methods: {
    handleClick (data) {
      const event = {
        type: this.$entities.Orderadmin_Storage_Entity_Movement_Inventory,
        event: 'storage.document.opened',
        data
      }

      this.$emit('click', event)
    }
  }
}
</script>
